<template>
    <ul class="order-list">
        <li v-for="item in curProductList" :key="item.key || item.orderid" class="order-item">
            <div class="order-main">
                <section class="order-title">
                    <span class="order-date">{{ type === 2 ? item.overdate : item.addtime }}</span>
                    <span class="order-no">订单号:{{ item.orderid }}</span>
                </section>
                <section class="order-prod">
                    <!-- <router-link class="order-img" :to="`/product/detail?commodityid=${item.commodityid}&commodity_details_id=${item.commodity_details_id}`"> -->
                    <span class="order-img">
                        <img :src="item.img" :alt="item.title">
                    </span>
                    <!-- </router-link> -->
                    <p class="order-txt">
                        <!-- <router-link class="order-txt-item text-ellipsis" :to="`/product/detail?commodityid=${item.commodityid}&commodity_details_id=${item.commodity_details_id}`"> -->
                        <span class="order-txt-item text-ellipsis">
                            {{ item.title }}
                        </span>
                        <!-- </router-link> -->
                        <span class="order-txt-item">{{ item.specs }}</span>
                        <span v-if="type===1" class="order-txt-item order-txt-tips">{{ orderStatusType[item.orderstatus] }}</span>
                        <span v-if="type===2||type===3" class="order-txt-item order-txt-tips">{{ afterMarketStatus[+item.status] }}</span>
                    </p>
                    <p class="order-price">
                        <span class="order-txt-item" :class="{ 'has-mb': type===2||type===3 }">￥ {{ item.money }}</span>
                        <span v-if="item.buy_num" class="order-txt-item">X {{ item.buy_num }}</span>
                        <span v-if="type===1" class="order-txt-item">总额：￥{{ item.moneyall }}</span>
                        <span v-if="type===1" class="order-txt-item">应付：<i class="red">￥{{ item.paymoney }}</i></span>
                    </p>
                </section>
            </div>
            <div class="order-footer">
                <!-- orderstatus=0,3,5待付款 -->
                <section class="order-tip">
                    <span v-if="item.paystatus==='0'&&[0, 3, 5].includes(item.orderstatus)">
                        <span v-if="type===2&&item.supplementenddate!=='0000-00-00'">{{item.supplementenddate.slice(0, 4) + '年' + item.supplementenddate.slice(5, 7) + '月' + item.supplementenddate.slice(8, 10) + '日' }}前订单可补款</span>
                        <span v-if="type===2&&item.supplementenddate!=='0000-00-00' && item.leftTime!==0">，</span>
                        <span v-if="item.leftTime!==0">剩余付款时限：{{ item.leftTime }}</span>
                    </span>
                </section>
                <section class="order-toolbar">
                    <!-- <a class="order-link" href="javascrip:;">联系客服</a> -->
                    <router-link v-if="type===1||type===3" class="order-link" :to="type===1?`/my/order-detail?id=${item.orderid}`:`/my/aftermarket-detail?aftermarketorderid=${item.aftermarketorderid}`">查看详情</router-link>
                    <!-- 订单没有申请售后 -->
                    <!-- <router-link v-if="type===1" class="order-link" :to="`/my/aftermarket-commit?id=${item.orderid}`">申请售后</router-link> -->
                    <!-- 只有 paystatus=0 和 status=0 orderstatus===9已取消  可以取消 -->
                    <!-- <a v-if="type===1&&item.status==='0'&&item.paystatus==='0'&&item.orderstatus!==9" class="order-link" href="javascrip:;" @click="cancelOrder(item.orderid, item)">取消订单</a> -->
                    <!-- 20201223.iscancel=1 的时候  有 取消订单按钮 -->
                    <a v-if="type===1&&item.iscancel===1" class="order-link" @click="cancelOrder(item.orderid, item)">取消订单</a>
                    <!-- 不超过两小时 是在 orderstatus 0 和5 的前提下 -->
                    <a v-if="type===1&&item.paystates==='1'&&([3].includes(item.orderstatus) || item.leftTime!==0)" class="btn-primary btn-small btn-rounder order-btn" @click="goPayment(item)">付款</a>
                    <a v-if="type===2" class="btn-primary btn-small btn-rounder order-btn" href="javascrip:;" @click="handleCommit(item)">申请售后</a>
                </section>
            </div>
        </li>
        <li v-if="curProductList.length === 0" class="nodata">暂无数据</li>
    </ul>
</template>
<script>
import { cancelOrder } from '@api/my'
import { checkOrderStatus, checkOrderEndStatus } from '@api/order'
export default {
    name: 'InfoList',
    props: {
        // 使用场景:1.order;2.退换货申请;3.售后记录;4...
        type: {
            type: Number,
            default: 1
        },
        productList: {
            type: Array,
            required: true,
            default: null
        }
    },
    data() {
        return {
            leftTime: '', // 限制中的剩余截止时间
            timer: null,
            // 0 预订，未付定金  1 预订，未定价 2 预订，已定价（没有资格付钱的那种） 3预订待付尾款  4 预订待发货  5 现货,待付款 6现货,已付款 待发货 7已发货 8已完成 9 已取消
            orderStatusType: [
                '预订，未付定金',
                '预订，未定价',
                '预订，已定价',
                '预订待付尾款',
                '预订待发货',
                '现货,待付款',
                '现货,已付款 待发货',
                '已发货',
                '已完成',
                '已取消'
            ],
            afterMarketStatus: [
                '',
                '待审核',
                '已通过',
                '未通过',
                '售后完成', 
                '已取消'
            ]
        }
    },
    computed: {
        curProductList() {
            return this.productList
        }
    },
    created() {
        if (this.type === 1) this.updateLeftTime()
    },
    methods: {
        // 刷新剩余付款时间
        updateLeftTime() {
            const self = this
            window.clearInterval(this.timer)
            // 1秒刷新一次
            this.timer = window.setInterval(async() => {
                self.curProductList.forEach(item => {
                    const addTime = new Date(item.addtime).getTime()
                    const nowTime = new Date().getTime()
                    // 最大时限2小时
                    const MaxTime = 2 * 60 * 60 * 1000
                    const leftTime = MaxTime - (nowTime - addTime)
                    const leftTimeStr = leftTime > 0 ? self.$utils.formatDuring(leftTime) : 0
                    this.$set(item, 'leftTime', leftTimeStr)
                })
            }, 1000)
        },
        // 付款
        async goPayment(item) {
            // 检查订单是否可以继续付款(有可能后台已取消)
            const params = {
                orderid: JSON.stringify([item.orderid]),
                money: item.paymoney,
                type: 1,
                // isuse_intergal: this.isuseIntergal
                isuse_intergal: 0 // 0不使用，1使用
            }
            // checkOrderEndStatus为付尾款检查: 1 可以正常支付  2001 一分钟内连续产生交易单最多5条  2002 一小时内最多10条   2 已付款 3订单异常
            const res = item.orderstatus === 3 ? await checkOrderEndStatus(params) : await checkOrderStatus(params)
            console.log('checkOrderStatus res', res)
            const resDetail = res.detail
            if (resDetail && resDetail.status === 1) {
                // 可以正常支付
                this.$router.push({ 
                    path: '/payment',
                    query: { 
                        orderid: JSON.stringify([item.orderid]),
                        money: item.paymoney,
                        orderstatus: item.orderstatus
                    }
                })
            } else {
                this.$message.warning('订单状态已更改，请刷新后重试')
                this.$emit('getData')
            }
        },
        // 售后申请
        handleCommit(item) {
            const { orderid, commodity_details_id } = item
            console.log(orderid, commodity_details_id)
            if (!orderid || !commodity_details_id) {
                this.$notify({ title: '提示', type: 'warning', message: '订单ID或商品ID不存在，请检查售后单！', position: 'bottom-right' })
                return
            }
            this.$router.push({
                path: '/my/aftermarket-commit',
                query: {
                    orderid,
                    commodity_details_id
                }
            })
        },
        // 取消订单
        async cancelOrder(orderid, item) {
            window.clearInterval(this.timer)
            this.$set(item, 'leftTime', 0)
            const res = await cancelOrder({ orderid })
            console.log('cancel order res', res)
            const resDetail = res.detail
            if (resDetail && resDetail.status === 1) {
                this.$emit('getData')
            }
            this.$notify({ title: '提示', type: resDetail.status === 1 ? 'success' : 'error', message: resDetail.status === 1 ? '订单取消成功' : '订单取消失败', position: 'bottom-right' })
        }
    }
}
</script>
<style lang="scss" scoped>
    .order-item {
        margin-bottom: 20px;
        border: 1px solid $lighter;
    }
    .order-main {
        border-bottom: 1px solid $lighter;
    }
    .order-title {
        @include lineHeight(36px);
        padding: 0 20px;
        color: $light;
        background-color: $grayest;
    }
    .order-date {
        margin-right: 20px;
    }
    .order-prod {
        position: relative;
        padding: 20px 100px 20px 20px;
    }
    .order-img {
        @include inlineBlock();
        margin-right: 20px;
        width: 96px;
        height: 96px;
        border: 1px solid $gray;
        > img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .order-txt {
        @include inlineBlock();
        width: 560px;
    }
    .order-txt-item {
        margin-bottom: 10px;
        display: block;
        color: $light;
        &:first-child {
            height: 15px;
            color: $black3;
        }
        &.has-mb {
            margin-bottom: 35px;
        }
    }
    .order-txt-tips {
        margin: 40px 0 0;
    }
    .order-price {
        position: absolute;
        right: 20px;
        top: 25px;
        text-align: right;
        .order-txt-item {
            &:first-child {
                color: $light;
            }
        }
    }
    .order-footer {
        @include lineHeight(44px);
        padding: 0 20px;
        color: $light;
    }
    .order-tip {
        float: left;
    }
    .order-toolbar {
        float: right;
    }
    .order-link {
        @include inlineBlock();
        margin-left: 10px;
        color: $light;
        cursor: pointer;
    }
    .order-btn {
        margin-left: 10px;
        cursor: pointer;
    }
    .nodata {
        height: 200px;
        line-height: 200px;
        text-align: center;
        color: $light;
    }
</style>
