<template>
    <section class="od-prod" :class="{ 'col-tow': orderHeadList.length === 2, 'col-four': orderHeadList.length === 4 }">
        <div class="prod-head">
            <div v-for="item in orderHeadList" :key="item" class="prod-col">{{ item }}</div>
        </div>
        <div class="prod-body">
            <div v-for="item in orderProdList" :key="item.commodity_details_id" class="prod-row">
                <div class="prod-col">
                    <div class="od-prod">
                        <router-link :to="`/product/detail?commodityid=${item.commodityid}&commodity_details_id=${item.commodity_details_id}`" class="od-img">
                            <img :src="item.img" :alt="item.name">
                        </router-link>
                        <p class="od-txt">
                            <router-link class="od-txt-item" :to="`/product/detail?commodityid=${item.commodityid}&commodity_details_id=${item.commodity_details_id}`">{{ item.title }}</router-link>
                            <span class="od-specs">{{ item.specs }}</span>
                        </p>
                    </div>
                </div>
                <div v-if="item.buy_num" class="prod-col">{{ item.buy_num }}</div>
                <div v-if="orderMoney" class="prod-col">{{ item.type === '2' ? item.money1 : item.money3 }}</div>
                <!-- 预订才有尾款 -->
                <div v-if="orderMoney && item.type === '2'" class="prod-col">{{ item.money2 }}</div>
                <div v-if="orderMoney" class="prod-col">{{ orderMoney.allmoney }}</div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'OrderProdList',
    props: {
        orderHeadList: {
            type: Array,
            default: null,
            required: true
        },
        orderProdList: {
            type: Array,
            default: null,
            required: true
        },
        orderMoney: {
            type: Object,
            default: null,
            required: true
        }
    },
    data() {
        return {
            reason: ['库存不足', '限购已满', '已截单', '已下架'] // 失效原因
        }
    }
}
</script>
<style lang="scss" scoped>
.prod-head {
    position: relative;
}
.prod-row {
    bod-bottom: 1px solid $grayest;
    &:last-child {
        bod-bottom: none;
    }
}
.prod-col {
    @include inlineBlock();
    vertical-align: top;
    width: 160px;
    padding: 20px;
    &:first-child {
        width: 520px;
    }
}
.prod-head {
    .prod-col {
        padding: 0 20px;
        font-size: 14px;
        &:first-child {
            padding-left: 0;
        }
    }
}
.col-four {
    .prod-col {
        width: 200px;
        &:first-child {
            width: 560px;
        }
    }
}
.od-prod {
    position: relative;
    padding-bottom: 0;
}
.od-img {
    @include inlineBlock();
    margin-right: 20px;
    width: 82px;
    height: 82px;
    border: 1px solid $gray;
    > img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.od-txt {
    @include inlineBlock();
    vertical-align: top;
    width: 300px;
}
.od-specs {
    color: $light;
}
.od-txt-item {
    display: block;
    color: $light;
    &:first-child {
        margin-bottom: 10px;
        height: 55px;
        overflow: hidden;
        color: $black3;
    }
    &:hover {
        color: $red;
    }
}
.od-invalid {
    .prod-body {
        color: $light;
    }
    .od-txt-item {
        color: $light;
    }
}
</style>
