<template>
    <div class="main my">
        <page-header :user-info="userInfo" />
        <div class="my-wrapper">
            <breadcrumbs :breadcrumbs-data="[{ name: '个人中心', url: '/my' }, { name: '我的订单', url: '/my/order/1' }, { name: '订单详情', url: '' }]" />
            <order-top-info :order-detail="orderDetail" />
            <div class="order-item order-item-row clearfix">
                <div class="order-pay">
                    <h3 class="order-pay-tit">{{ `${['现货', '预订'][+orderDetail.type - 1]}` }}</h3>
                    <p class="order-pay-item">
                        <span class="order-pay-name">已付金额</span>
                        <span class="order-pay-price">￥{{ orderMoney.yifumoney }}</span>
                    </p>
                    <p class="order-pay-item">
                        <span class="order-pay-name">应付金额</span>
                        <span class="order-pay-price red">￥{{ orderMoney.daifumoney }}</span>
                    </p>
                    <!-- orderDetail===9为已取消订单 -->
                    <router-link v-if="[0, 3, 5].includes(orderDetail.orderstatus)" class="btn-primary btn-round pay-btn" :to="{ path: '/payment', query: { orderid: JSON.stringify([orderDetail.orderid]), money: orderMoney.daifumoney, orderstatus: orderDetail.orderstatus }}">付款</router-link>
                    <!-- orderDetail===9为已取消订单 -->
                    <!-- <a v-if="[0, 5].includes(orderDetail.orderstatus)" href="javascript:;" class="btn-text" @click="cancelOrder(orderDetail.orderid)">取消订单</a> -->
                    <!-- 20201223.iscancel=1 的时候  有 取消订单按钮 -->
                    <a v-if="orderDetail.iscancel===1" href="javascript:;" class="btn-text" @click="cancelOrder(orderDetail.orderid)">取消订单</a>
                    <span v-if="orderDetail.orderstatus===9" class="btn-text">订单已取消</span>
                </div>
                <div class="order-process">
                    <h3 v-if="[0, 5].includes(orderDetail.orderstatus)" class="order-process-tit">
                        <i class="icon icon-clock" />
                        <span>该订单会为您保留2.0小时（从下单之日算起），2.0小时之后如果还未付款，系统将自动取消该订单。</span>
                    </h3>
                    <div class="process-list" :class="{ 'process-five': orderDetail.type === '1', 'process-two': orderDetail.orderstatus === 9 }">
                        <section v-if="orderDetail.time1" class="process-item">
                            <p class="mainicon"><i class="icon icon-xiadan-a" :class="{ 'icon-xiadan-u': !orderDetail.time3,'icon-xiadan-a': orderDetail.time1 }" /></p>
                            <p class="process-tit">提交订单<br>{{ orderDetail.time1 || '-' }}</p>
                            <i class="icon icon-lianjie-a icondot" />
                        </section>
                        <!-- 预订非取消 -->
                        <section v-if="orderDetail.type === '2' && orderDetail.orderstatus !== 9 && orderDetail.time2" class="process-item">
                            <p class="mainicon"><i class="icon icon-xiadan-a" />
                            </p><p class="process-tit">预定，未定价<br>{{ orderDetail.time2 || '-' }}</p>
                            <i class="icon icon-lianjie-a icondot" />
                        </section>
                        <!-- 预订非取消 -->
                        <section v-if="orderDetail.type === '2' && orderDetail.orderstatus !== 9" class="process-item">
                            <p class="mainicon"><i class="icon" :class="{ 'icon-yifukuan-u': !orderDetail.time3,'icon-yifukuan-a': orderDetail.time3 }" /></p>
                            <p class="process-tit">预订已付款<br>{{ orderDetail.time3 || '-' }}</p>
                            <i class="icon icon-lianjie-u icondot" />
                        </section>
                        <!-- 预订非取消 -->
                        <section v-if="orderDetail.type === '2' && orderDetail.orderstatus !== 9" class="process-item">
                            <p class="mainicon"><i class="icon " :class="{ 'icon-xiadan-U': !orderDetail.time4,'icon-xiadan-a': orderDetail.time4 }" /></p>
                            <p class="process-tit">预定，已定价<br>{{ orderDetail.time4 || '-' }}</p>
                            <i class="icon icon-lianjie-u icondot" />
                        </section>
                        <section v-if="orderDetail.orderstatus !== 9" class="process-item">
                            <p class="mainicon"><i class="icon" :class="{ 'icon-yifukuan-u': !orderDetail.time5,'icon-yifukuan-a': orderDetail.time5 }" /></p>
                            <p class="process-tit">已付款<br>{{ orderDetail.time5 || '-' }}</p>
                            <i class="icon icon-lianjie-u icondot" />
                        </section>
                        <section v-if="orderDetail.orderstatus !== 9" class="process-item">
                            <p class="mainicon"><i class="icon" :class="{ 'icon-order-fh-u': !orderDetail.time6,'icon-order-fh-a': orderDetail.time6 }" /></p>
                            <p class="process-tit">待发货<br>{{ orderDetail.time6 || '-' }}</p>
                            <i class="icon icon-lianjie-u icondot" />
                        </section>
                        <section v-if="orderDetail.orderstatus !== 9" class="process-item">
                            <p class="mainicon"><i class="icon" :class="{ 'icon-kuaidi-u': !orderDetail.time7,'icon-kuaidi-a': orderDetail.time7 }" /></p>
                            <p class="process-tit">已发货<br>{{ orderDetail.time7 || '-' }}</p>
                            <i class="icon icon-lianjie-u icondot" />
                        </section>
                        <section v-if="orderDetail.orderstatus !== 9" class="process-item">
                            <p class="mainicon"><i class="icon" :class="{ 'icon-weiwancheng-u': !orderDetail.time8,'icon-yiwancheng': orderDetail.time8 }" /></p>
                            <p class="process-tit">已完成<br>{{ orderDetail.time8 || '-' }}</p>
                            <i class="icon icon-lianjie-u icondot" />
                        </section>
                        <section v-if="orderDetail.orderstatus === 9" class="process-item">
                            <p class="mainicon"><i class="icon" :class="{ 'icon-weiwancheng-u': orderDetail.orderstatus!==9,'icon-yiwancheng': orderDetail.orderstatus===9 }" /></p>
                            <p class="process-tit">已取消<br>{{ orderDetail.canceltime || '-' }}</p>
                            <i class="icon icon-lianjie-u icondot" />
                        </section>
                    </div>
                </div>
            </div>
            <div class="order-item order-details">
                <div class="details-row">
                    <div class="order-details-col">
                        <p class="details-item">
                            <span class="detail-tit">{{ orderDetail.type==='1' ? '金额' : '定金金额' }}</span>
                            <span class="detail-con">￥{{ orderDetail[orderDetail.type==='1' ? 'xianhuomoney' : 'yudingmoney'] }}</span>
                        </p>
                        <p class="details-item">
                            <span class="detail-tit">优惠减免</span>
                            <span class="detail-con">￥{{ orderDetail[orderDetail.type==='1' ? 'xianhuoyouhuimoney' : 'yudingyouhuimoney'] }}</span>
                        </p>
                        <p v-if="orderDetail.type==='2'" class="details-item">
                            <span class="detail-tit">尾款金额</span>
                            <span class="detail-con">￥{{ orderDetail.weikuanmoney }}</span>
                        </p>
                        <p v-if="orderDetail.type==='2'" class="details-item">
                            <span class="detail-tit">优惠减免</span>
                            <span class="detail-con">￥{{ orderDetail.weikuanyouhuimoney }}</span>
                        </p>
                        <!-- <p class="details-item">
                            <span class="detail-tit">K点支付</span>
                            <span class="detail-con">￥{{ orderDetail[orderDetail.type==='1' ? 'xianhuoyouhuiintegral' : 'yudingyouhuiintegral'] }}</span>
                        </p>
                        <p class="details-item">
                            <span class="detail-tit">支付金额</span>
                            <span class="detail-con">￥{{ orderDetail[orderDetail.type==='1' ? 'xianhuopaymoney' : 'yudingpaymoney'] }}</span>
                        </p> -->
                    </div>
                    <div class="order-details-col">
                        <p class="details-item">
                            <span class="detail-tit">配送方式</span>
                            <span class="detail-con">{{ orderDetail.yunfeiname }}</span>
                        </p>
                        <p class="details-item">
                            <span class="detail-tit">配送单号</span>
                            <el-popover
                                v-if="orderDetail.yunfeiorder"
                                v-model="orderDetail.showPopover"
                                title="物流信息"
                                width="400"
                                trigger="manual"
                            >
                                <i class="el-icon-close pop-close" title="关闭" @click="$set(orderDetail, 'showPopover', false)" />
                                <div class="pop-content">
                                    <p v-for="(delivery, index) in deliveryList" :key="index">{{ delivery.time + ',' + delivery.context }}</p>
                                    <p v-if="!deliveryList[0]">暂无相关物流信息</p>
                                </div>
                                <span slot="reference" class="detail-con">{{ orderDetail.yunfeiorder }} <a class="detail-link" href="javascript:;" @click.prevent="getKuaidiInfo(orderDetail)">查看</a></span>
                                <!-- <a slot="reference" href="javascript:;" @click.prevent="getKuaidiInfo(item)">查看物流信息</a> -->
                            </el-popover>
                        </p>
                        <p class="details-item">
                            <span class="detail-tit">发货日期</span>
                            <span class="detail-con">{{ orderDetail.yunfeistartdate }}</span>
                        </p>
                        <p class="details-item">
                            <span class="detail-tit">收货日期</span>
                            <span class="detail-con">{{ orderDetail.yunfeienddate }}</span>
                        </p>
                    </div>
                    <div class="order-details-col">
                        <p class="details-item">
                            <span class="detail-tit">收货人</span>
                            <span class="detail-con">{{ orderDetail.takeusername }}</span>
                        </p>
                        <p class="details-item">
                            <span class="detail-tit">手机号码</span>
                            <span class="detail-con">{{ orderDetail.takeusermobile }}</span>
                        </p>
                        <p class="details-item">
                            <span class="detail-tit">地址</span>
                            <span class="detail-con">{{ orderDetail.takeuseraddress }}</span>
                        </p>
                    </div>
                </div>
                <!-- <div class="details-row">
                    <div class="order-details-col">
                        <p v-if="orderDetail.type==='2'" class="details-item">
                            <span class="detail-tit">尾款金额</span>
                            <span class="detail-con">￥{{ orderDetail.weikuanmoney }}</span>
                        </p>
                        <p v-if="orderDetail.type==='2'" class="details-item">
                            <span class="detail-tit">优惠减免</span>
                            <span class="detail-con">￥{{ orderDetail.weikuanyouhuimoney }}</span>
                        </p> -->
                <!-- <p v-if="orderDetail.type==='2'" class="details-item">
                            <span class="detail-tit">K点支付</span>
                            <span class="detail-con">￥{{ orderDetail.weikuanyouhuiintegral }}</span>
                        </p>
                        <p v-if="orderDetail.type==='2'" class="details-item">
                            <span class="detail-tit">支付金额</span>
                            <span class="detail-con">￥{{ orderDetail.weikuanpaymoney }}</span>
                        </p> -->
                <!-- </div> -->
                <!-- <div class="order-details-col"> -->
                <!-- <p class="details-item">
                            <span class="detail-tit">运费金额</span>
                            <span class="detail-con">￥{{ orderDetail.yunfeimoney }}</span>
                        </p> -->
                <!-- <p class="details-item">
                            <span class="detail-tit">其余抵扣</span>
                            <span class="detail-con">￥{{ orderDetail.yunfeimoney }}</span>
                        </p>
                        <p class="details-item">
                            <span class="detail-tit">K点抵扣</span>
                            <span class="detail-con">￥{{ orderDetail.yunfeimoney }}</span>
                        </p> -->
                <!-- <p class="details-item">
                            <span class="detail-tit">支付金额</span>
                            <span class="detail-con">￥{{ orderDetail.yunfeimoney }}</span>
                        </p> -->
                <!-- </div> -->
                <!-- </div> -->
            </div>
            <product-list v-if="commodity[0]" class="order-item order-item-prod" :order-head-list="validOrderHeadList" :order-prod-list="commodity" :order-money="orderMoney" />
            <order-price class="order-item order-price" :price-obj="orderMoney" />
        </div>
        <page-footer />
    </div>
</template>
<script>
import pageHeader from '@c/pageHeader'
import PageFooter from '@c/pageFooter'
import breadcrumbs from '@c/breadcrumbs'
import orderTopInfo from './orderTopInfo.vue'
import productList from './productList.vue'
import orderPrice from './orderPrice.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { myOrderDetail, cancelOrder, getKuaidiInfo } from '@api/my'
// import '../../mock'
export default {
    name: 'AftermarketDetail',
    components: {
        pageHeader,
        PageFooter,
        breadcrumbs,
        orderTopInfo,
        productList,
        orderPrice
    },
    mixins: [mixin, authMixin],
    data() {
        return {
            orderDetail: {},
            commodity: [],
            orderMoney: {},
            validOrderHeadList: ['商品', '数量', '定金（元）', '尾款（元）', '总金额（元）'],
            deliveryList: [] // 物流信息列表
        }
    },
    computed: {
        showBuyBtn() {
            console.log('待付金额', this.orderMoney.daifumoney)
            console.log('订单过期时间', new Date().getTime() - new Date(this.orderDetail.addtime).getTime())
            if ([0, '0.00'].includes(this.orderMoney.daifumoney)) { // 待付金额为0不显示付款按钮
                return false
            } else if (new Date().getTime() - new Date(this.orderDetail.addtime).getTime() > 2 * 60 * 60 * 1000) { // 下单超过2小时
                return false
            } else if (this.orderDetail.orderstatus === 9) { // 订单已取消
                return false
            } else {
                return true
            }
        }
    },
    methods: {
        async myOrderDetail(orderid) {
            const res = await myOrderDetail({ orderid })
            console.log('order res', res)
            if (res.code === 1001) {
                this.orderDetail = Object.assign({}, res.list)
                this.commodity = res.list.commodity
                // 添加商品类型('1'.现货，'2'.预订)
                this.commodity.forEach(item => {
                    this.$set(item, 'type', this.orderDetail.type)
                })
                // 现货头部不一样单独写(定金改为定价，没有尾款)
                if (this.commodity[0] && this.commodity[0].type === '1') {
                    this.validOrderHeadList = ['商品', '数量', '定价（元）', '总金额（元）']
                }
                this.orderMoney = Object.assign({}, res.list.ordermoney)
            }
        },
        // 取消订单
        async cancelOrder(orderid) {
            const res = await cancelOrder({ orderid })
            console.log('cancel order res', res)
            const resDetail = res.detail
            if (resDetail && resDetail.status === 1) {
                this.$router.push('/my/order/1')
            }
            this.$notify({ title: '提示', type: resDetail.status === 1 ? 'success' : 'error', message: resDetail.status === 1 ? '订单取消成功' : '订单取消失败', position: 'bottom-right' })
        },
        // 获取快递详细信息
        async getKuaidiInfo(item) {
            console.log('item', item)
            if (!item) return
            this.deliveryList = []
            this.$set(item, 'showPopover', true)
            const res = await getKuaidiInfo({ fareorder: item.yunfeiorder })
            this.deliveryList = res.detail.status === 1 ? res.detail.content : []
            console.log('res', res)
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        next(vm => {
            console.log('vm.$route.query', vm.$route.query)
            if (vm.$route.query && vm.$route.query.id) {
                vm.myOrderDetail(vm.$route.query.id)
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.order-logo {
    position: relative;
    height: auto;
    padding-bottom: 0;
}
.order-item {
    position: relative;
    width: 1200px;
    min-height: 120px;
    padding: 20px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.order-item-prod {
        padding-bottom: 0;
    }
    &.mt20 {
        margin-top: 20px;
    }
}
.order-item-row {
    padding: 0;
    height: 222px;
    background-color: transparent;
}
.order-pay {
    float: left;
    width: 285px;
    height: 100%;
    margin-right: 10px;
    padding: 20px 60px;
    background-color: $white;
}
.order-pay-tit {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: $red;
}
.order-pay-item {
    font-size: 14px;
    color: $light;
}
.order-pay-name {
    @include inlineBlock();
    margin: 0 30px 10px 0;
}
.btn-primary,
.btn-text {
    margin: 10px auto;
    display: block;
    width: 120px;
    text-align: center;
}
span.btn-text {
    color: $light;
}
.order-process {
    float: left;
    width: 903px;
    height: 100%;
    background-color: $white;
}
.order-process-tit {
    padding: 20px;
    font-weight: normal;
    font-size: 12px;
    color: $light;
    > * {
        @include inlineBlock();
        margin-right: 5px;
    }
}
.process-list {
    padding: 10px;
    white-space: nowrap;
}
.mainicon {
    display: block;
    margin-bottom: 6px;
    height: 36px;
    line-height: 36px;
}
.icondot {
    position: absolute;
    top: 12px;
    left: 90px;
}
.process-item {
    @include inlineBlock();
    vertical-align: top;
    position: relative;
    margin: 10px 40px 10px 0;
    width: 75px;
    text-align: center;
    white-space: normal;
    color: $dark;
    &:last-child {
        margin-right: 0;
        .icondot {
            display: none;
        }
    }
}
// 现货流程5个
.process-five .process-item {
    margin-right: 120px;
    .icon {
        left: 130px;
    }
    &:last-child {
        margin-right: 0;
    }
}
// 已取消流程2个
.process-two .process-item {
    margin-right: 700px;
    .icon {
        left: 390px;
    }
    &:last-child {
        margin-right: 0;
    }
}
.details-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.order-details-col {
    @include inlineBlock();
    vertical-align: top;
    width: 35%;
    line-height: 2;
    color: $light;
    &:nth-child(3) {
        width: 30%;
    }
}
.detail-tit,
.detail-con {
    @include inlineBlock();
    width: 200px;
    vertical-align: top;
}
.detail-link {
    color: $red;
    &:hover {
        color: $reder;
    }
}
.detail-tit {
    width: 120px;
    margin-right: 20px;
    text-align: right;
}
.pop-close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    &:hover {
        color: #409efe;
    }
}
.pop-content {
    padding: 5px;
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    max-height: 180px;
    overflow: auto;
    p {
        margin-bottom: 10px;
    }
}
</style>
