<template>
    <section class="afd-list">
        <div class="afd-list-in">
            <h4 class="aftermarket-title">订单详情</h4>
            <dl class="afd-list-item">
                <dt>订单类型</dt>
                <dd>{{ ['现货', '预订'][+orderDetail.type - 1] }}</dd>
            </dl>
            <dl class="afd-list-item">
                <dt>订单号</dt>
                <dd>{{ orderDetail.orderid }}</dd>
            </dl>
            <dl class="afd-list-item">
                <dt>下单时间</dt>
                <dd>{{ orderDetail.addtime }}</dd>
            </dl>
        </div>
    </section>
</template>
<script>
export default {
    name: 'OrderTopInfo',
    props: {
        orderDetail: {
            default: null,
            type: Object
        }
    }
}
</script>
<style lang="scss" scoped>
.aftermarket-title {
    padding: 20px 0;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
}
.afd-list {
    margin-bottom: 20px;
    border-top: 1px solid $gray;
    background-color: $white;
}
.afd-list-in {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
}
.afd-list-item {
    @include inlineBlock();
    width: 300px;
    margin-bottom: 20px;
    color: $light;
    dt {
        margin-bottom: 10px;
        font-size: 14px;
    }
    dd {
        font-size: 18px;
    }
    &:last-child {
        width: 200px;
        margin-left: 400px;
    }
}
</style>
