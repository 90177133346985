<template>
    <div class="my-order">
        <div class="my-head">
            <h3 class="my-title">我的订单</h3>
        </div>
        <div class="my-body">
            <div class="tap-wrapper">
                <el-tabs v-model="activeName" class="tab-order" @tab-click="handleClick">
                    <el-tab-pane v-for="item in tabList" :key="item.name" class="list-wrapper" :label="item.label" :name="item.name">
                        <my-product-list :product-list="orderList" @getData="myOrderList(+item.name - 1)" />
                    </el-tab-pane>
                </el-tabs>
            </div>
            <!-- <my-list-search @onSearch="handleSearch"></my-list-search> -->
        </div>
        <div class="pagination-box">
            <el-pagination v-if="orderList[0]" class="page-custom" background layout="prev, pager, next" :page-size="20" :total="total" :hide-on-single-page="true" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>
<script>
import myProductList from '../myProductList'
import myListSearch from '../myListSearch'
import { myOrderList } from '@api/my'
// import '../mock'
export default {
    name: 'MyOrder',
    components: {
        myProductList,
        myListSearch
    },
    data() {
        return {
            activeName: '1',
            tabList: [
                { name: '1', label: '全部订单' },
                { name: '2', label: '预订' },
                { name: '3', label: '待付款' },
                { name: '4', label: '待发货' },
                { name: '7', label: '已发货' },
                { name: '5', label: '已完成' },
                { name: '6', label: '已取消' }
            ],
            orderList: [
                // { orderid: '1' },
                // { orderid: '2' },
                // { orderid: '3' },
                // { orderid: '4' },
                // { orderid: '5' },
                // { orderid: '6' },
                // { orderid: '7' },
                // { orderid: '8' }
            ],
            page: 1,
            total: 0
        }
    },
    watch: {
        '$route'(to, from) {
            this.getParams()
        }
    },
    created() {
        this.getParams()
    },
    methods: {
        handleSearch(key) {
            console.log('key', key)
            this.$router.push(`/my/order/1`)
            this.myOrderList(0, key)
        },
        getParams() {
            const type = this.$router.history.current.params.type
            console.log('type', type)
            if (type) {
                this.activeName = type
                this.myOrderList(Number(type) - 1)
            }
        },
        // 返回orderstatus: // 0 预订，未付定金  1 预订，未定价 2 预订，已定价（没有资格付钱的那种） 3预订待付尾款  4 预订待发货  5 现货,待付款 6现货,已付款 待发货 7已发货 8已完成 9 已取消
        async myOrderList(status = 0, title) {
            const res = await myOrderList({ status, title, page: this.page })
            console.log('order res', res)
            if (res && res.code === 1001) {
                this.orderList = res.list.list
                this.orderList.forEach(item => {
                    console.log('item.commodity[0]', item.commodity[0])
                    const commodity = item.commodity[0]
                    if (commodity) {
                        this.$set(item, 'buy_num', commodity.buy_num)
                        this.$set(item, 'img', commodity.img)
                        this.$set(item, 'title', commodity.title)
                        this.$set(item, 'specs', commodity.specs)
                        this.$set(item, 'money', commodity.money)
                    }
                })
                this.total = res.list.count
            }
        },
        handleClick(val) {
            console.log('val', val)
            this.page = 1
            this.$router.push(`/my/order/${val.name}`)
        },
        // 点击页码
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.page = val
            this.myOrderList()
            const appDom = document.querySelector('html')
            if(appDom) appDom.scrollTo(0, 0)
        }
    }
}
</script>
<style lang="scss" scoped>
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
}
.my-body {
    min-height: 1000px;
    position: relative;
    padding: 10px 20px;
    background-color: $white;
}
</style>
