<template>
    <div class="order-search">
        <el-input v-model="searchKey" placeholder="输入商品标题或订单号进行搜索">
            <el-button slot="append" @click.native.stop="handleSearch">搜索</el-button>
        </el-input>
    </div>
</template>
<script>
export default {
    name: 'MyListSearch',
    props: {

    },
    data() {
        return {
            searchKey: ''
        }
    },
    methods: {
        handleSearch() {
            console.log('searchKey', this.searchKey)
            this.$emit('onSearch', this.searchKey)
        }
    }
}
</script>
<style lang="scss" scoped>
    .order-search {
        width: 260px;
        position: absolute;
        top: 20px;
        right: 20px;
    }
</style>
